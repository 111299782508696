import { Descriptions, DescriptionsProps, Row, Skeleton } from 'antd';
import { PageCard } from 'src/common/styles/styles';
import { Col18, Col6 } from 'src/components/Columns';
import { Image } from 'antd';
import * as Styles from './styles'
import { Assets } from 'src/common/assets';
import { Company } from 'src/common/models/company';
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/reducer';
import { SystemDescriptions } from 'src/common/descriptions';
import { serviceLoading } from 'src/common/apiLoader/state/selection';
import { GET_COMPANY_API } from 'src/common/state/actionTypes';

interface ReduxProps {
    company?: Company
    isGettingCompanay: boolean
}

export const CompanyData = () => {
    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        company: state.common.company,
        isGettingCompanay: serviceLoading(state, [GET_COMPANY_API]),
    }))

    const descriptions = SystemDescriptions.COMPONENTS.DASHBOARD
    const EMPTY_STRING: string = '--'

    const items: DescriptionsProps['items'] = [
        {
            key: 'TRADE_NAME',
            label: 'Trade Name',
            children: reduxProps.company?.tradeName || EMPTY_STRING,
        },
        {
            key: 'COMMERCIAL_NAME',
            label: 'Commercial Name',
            children: reduxProps.company?.comercialName || EMPTY_STRING,
            span: 2,
        },
        {
            key: 'NIT',
            label: descriptions.COMPANY_INFORMATION.NIT,
            children: reduxProps.company?.nit || EMPTY_STRING,
        },
        {
            key: 'PHONE',
            label: descriptions.COMPANY_INFORMATION.PHONE,
            children: EMPTY_STRING,
        },
        {
            key: 'EMAIL',
            label: descriptions.COMPANY_INFORMATION.EMAIL,
            children: reduxProps.company?.email || EMPTY_STRING,
        },
        {
            key: 'ZIPCODE',
            label: descriptions.COMPANY_INFORMATION.ZIPCODE,
            children: reduxProps.company?.zipcode || EMPTY_STRING,
        },
        {
            key: 'ADDRESS',
            label: descriptions.COMPANY_INFORMATION.ADDRESS,
            children: reduxProps.company
                ? `${reduxProps.company?.address}, ${reduxProps.company?.city}, ${reduxProps.company?.state} `
                : EMPTY_STRING,
        },
    ];

    const renderInitalLoader = () => (
        <Skeleton active />
    )

    const renderData = () => (
        <Row>
            <Col6>
                <Styles.LeftSideContainer>
                    <Image
                        width={125}
                        src={Assets.COMPANY_LOGO_LOCAL}
                        style={{
                            borderRadius: '50%',
                        }}
                        preview={false}
                    />
                    <label>{reduxProps.company?.comercialName}</label>
                </Styles.LeftSideContainer>
            </Col6>
            <Col18>
                <Descriptions title={descriptions.COMPANY_INFORMATION.TITLE} items={items} />
            </Col18>
        </Row>
    )

    const renderCompanyData = () => (
        <PageCard size="small" title="" style={{ width: '100%' }}>
            {
                reduxProps.isGettingCompanay
                    ? renderInitalLoader()
                    : renderData()
            }
        </PageCard>
    )

    return (
        renderCompanyData()
    )
}
